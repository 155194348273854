import React, { useContext, useEffect, useState } from "react"
import { ArrowDown, ArrowRight } from "react-feather"
import HelmetHead from "../../components/HelmetHead"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"

import axios from "axios"
import Alert from "react-bootstrap/Alert"
import { sendConversion } from "../../components/GTM"
import { Link } from "gatsby"
import BackgroundSection from "../../components/BackgroundSection"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"
import { useCookies } from "react-cookie"
import { FormGroup, Input, Label } from "reactstrap"

const Kontakt = ({ location }) => {
  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [contactPhone, setContactPhone] = useState("")
  const [contactRequest, setContactRequest] = useState("maklerempfehlung")
  const [contactMessage, setContactMessage] = useState("")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleContactSubmit = event => {
    event.preventDefault()

    setIsLoading(true)
    sendConversion("contact_submit")
    axios
      .post("/.netlify/functions/send_mail", {
        contactName,
        contactEmail,
        contactPhone,
        contactRequest,
        contactMessage,
        ref: location.ref,
      })
      .then(response => {
        // console.log(response)
        setShowSuccessAlert(true)
        // clear vars
        setContactName("")
        setContactEmail("")
        setContactPhone("")
        setContactRequest("maklerempfehlung")
        setContactMessage("")
      })
      .catch(error => {
        console.log(error)
        alert(
          "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder schreiben Sie uns eine E-Mail."
        )
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        color: "light",
      },
    }))
  }, [])

  const [cookies] = useCookies()
  const mzCookies = cookies.maklerzentrale || {}

  return (
    <>
      <HelmetHead title="Kontakt Maklerzentrale" />
      <BackgroundSection
        src="AdobeStock_269827206.jpg"
        className="bg-cover"
        style={{ backgroundPosition: "center 20% !important" }}
      >
        <div className="container">
          <div
            className="row justify-content-center align-items-end pb-10"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 fw-bold text-primary text-shadow-custom-white">
                Ihr Kontakt zur Maklerzentrale
              </h1>

              <p className="lead text-primary text-shadow-custom-white mb-0">
                Für den optimalen Immobilienverkauf
              </p>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 border-bottom border-gray-300">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="kontakt-info" />
              <a
                href="#kontakt-info"
                className="btn btn-white btn-rounded-circle shadow mt-n11"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 text-center border-none-sm-down border-end border-gray-300">
              <h6 className="text-uppercase text-secondary mb-1">
                Schreiben Sie Uns
              </h6>
              <div className="mb-5 mb-sm-0">
                <a
                  href="#chat"
                  onClick={() =>
                    window &&
                    ((!mzCookies.thirdParty &&
                      alert(
                        'Wir nehmen Datenschutz ernst.\n\nBitte erlauben Sie unsere Cookies oder "Dienste von anderen Anbietern" unter den "Cookie-Einstellungen" in der Datenschutzerklärung um den Chat zu aktivieren.\n\n\n'
                      )) ||
                      (window.$crisp && window.$crisp.do("chat:open")))
                  }
                  className="h4 text-primary"
                >
                  Jetzt im Chat!
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-4 text-center border-none-sm-down border-end border-gray-300">
              <h6 className="text-uppercase text-secondary mb-1">
                Kostenlose Servicenummer
              </h6>
              <div className="mb-5 mb-sm-0">
                <a href="tel:+498002242020" className="h4">
                  0800 2242020
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-4 text-center">
              <h6 className="text-uppercase text-secondary mb-1">E-Mail</h6>
              <a href="mailto:info@maklerzentrale.com" className="h4">
                info@maklerzentrale.com
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-8 pt-md-11 pb-11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">
                Möchten Sie Ihre Immobilie optimal verkaufen?
              </h2>
              <p className="fs-lg text-muted mb-7 mb-md-9">
                Dann fordern Sie gerne unsere kostenlose Immobilienbewertung
                oder unabhängige Maklerempfehlung für den erfolgreichen
                Immobilienverkauf an!
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-10">
              <div className="scroll-beacon" id="unverbindliche-anfrage" />
              <div className="card shadow-lg card-border card-border-lg border-primary-desat">
                <div className="card-body">
                  <form onSubmit={handleContactSubmit}>
                    {showSuccessAlert ? (
                      <Alert
                        variant="success"
                        onClose={() => setShowSuccessAlert(false)}
                        dismissible
                        className="mb-5"
                      >
                        Vielen Dank für Ihre Anfrage! Wir werden uns in Kürze
                        bei Ihnen melden.
                      </Alert>
                    ) : null}
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-5">
                          <label className="form-label" htmlFor="contactName">
                            Ihr Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="contactName"
                            value={contactName}
                            onChange={e => setContactName(e.target.value)}
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-5">
                          <label className="form-label" htmlFor="contactEmail">
                            Ihre E-Mail
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            id="contactEmail"
                            value={contactEmail}
                            onChange={e => setContactEmail(e.target.value)}
                            placeholder="E-Mail"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-5">
                          <label className="form-label" htmlFor="contactPhone">
                            Ihre Telefonnummer
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="contactPhone"
                            value={contactPhone}
                            onChange={e => setContactPhone(e.target.value)}
                            required
                            placeholder="Telefonnummer"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-5">
                          <label
                            className="form-label"
                            htmlFor="contactRequest"
                          >
                            Ihre Anfrage
                          </label>
                          <select
                            id="contactRequest"
                            className="form-select form-select-sm"
                            onChange={e => setContactRequest(e.target.value)}
                            value={contactRequest}
                          >
                            <option value="maklerempfehlung">
                              Maklerempfehlung
                            </option>
                            <option value="immobilienbewertung">
                              Immobilienbewertung
                            </option>
                            <option value="beratung">Beratung</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-6">
                          <label
                            className="form-label"
                            htmlFor="contactMessage"
                          >
                            Ihre Nachricht
                          </label>
                          <textarea
                            className="form-control"
                            id="contactMessage"
                            value={contactMessage}
                            onChange={e => setContactMessage(e.target.value)}
                            rows="4"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-12 col-sm-7 col-md-8">
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="zustimmungDatenschutzKontakt"
                            name="zustimmungDatenschutzKontakt"
                            className="mb-3 mb-sm-0"
                            required
                          />
                          <Label check for="zustimmungDatenschutzKontakt">
                            <span
                              className="text-gray-600 d-inline-block"
                              style={{ fontSize: "12px" }}
                            >
                              Ich stimme den{" "}
                              <a
                                href="/de/datenschutz/"
                                target="_blank"
                                className="text-muted"
                              >
                                Datenschutz­bestimmungen
                              </a>{" "}
                              und einer Kontakt­aufnahme durch die
                              Maklerzentrale per E-Mail oder Telefon für
                              Rückfragen oder zu Informations­zwecken zu.
                            </span>
                          </Label>
                        </FormGroup>
                      </div>
                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-primary lift"
                          disabled={isLoading}
                          style={{ minWidth: "150px" }}
                        >
                          {isLoading && (
                            <span
                              className="spinner-grow spinner-grow-sm me-2"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Senden
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="pb-6 pb-md-8 border-bottom border-gray-300">
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <p className="fs-lg text-muted mb-0">
                  <b>Erfolgreich mit der Maklerzentrale</b>
                  <br />
                  Werden Sie Teil unseres Kompetenzteams
                </p>
              </div>
              <div className="col-12 col-md-auto mt-3 mt-md-0">
                <Link to="/de/karriere" className="btn btn-primary-soft lift">
                  Karriere <ArrowRight size={15} className="ms-auto" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Kontakt
